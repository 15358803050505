<template>
  <CardContainer title="Recent Occupancy Rate" v-if="stats">
    <template slot="chart">
      <CChartLine
        :datasets="chartData"
        :labels="chartLabel"
        :options="chartOption"
      />
    </template>
  </CardContainer>
</template>

<script>
import CardContainer from '@/containers/DashboardCard.vue'
import { CChartLine } from '@coreui/vue-chartjs'

export default {
  name: 'RecentOccupancyRate',
  components: {
    CardContainer,
    CChartLine
  },
  props: {
    stats: {type: Array}
  },
  computed: {
    chartData() {
      let rtn = {
        label: 'Occupancy(%)',
        data: [],
        borderColor: '#2586a4',
        fill: false,
        tension: 0.4
      }
      if (this.stats.length <= 0) return [rtn];
      for (let stat of this.stats) {
        if (stat.occupied === 0 && stat.free === 0) {
          rtn.data.push(NaN);
          continue;
        }
        let occupancy = Math.round(stat.occupied / (stat.occupied+stat.free) * 100);
        rtn.data.push(occupancy);
      }
      return [rtn];
    },
    chartLabel() {
      if (this.stats.length <= 0) return ['No data'];
      let rtn = [];
      if (this.stats.length === 24) {
        for (let i in this.stats) {
          const created_at = this.stats[i].created_at;
          const d = new Date(created_at);
          const hour = d.getHours();
          const ampm = 'AM';
          if (hour >= 12) ampm = 'PM';
          if (i % 4 === 0) rtn.push(ampm+' '+hour);
          else rtn.push('');
        }
      } else if (this.stats.length === 7) {
        rtn = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      } else {
        for (let i in this.stats) {
          if (i === 0 || i % 5 === 4) {
            rtn.push(+i+1);
          } else {
            rtn.push('');
          }
        }
      }
      return rtn;
    },
    chartOption() {
      return {
        legend: {
          position: 'top', // top, right, bottom, left
          align: 'center', // 'start', 'center', 'end'
          labels: {
            fontSize: 12
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: false
            },
            ticks: {
              beginAtZero: true,
              max: 100
            },
            gridLines: {
              display: true
            }
          }]
        }
      };
    }
  }
}
</script>