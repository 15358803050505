<template>
  <div>
    <CButtonGroup class="mb-4">
      <CButton :color="t==='daily'?'primary':'secondary'" @click="t='daily'">{{$t('cloud.title.stat_daily')}}</CButton>
      <CButton :color="t==='weekly'?'primary':'secondary'" @click="t='weekly'">{{$t('cloud.title.stat_weekly')}}</CButton>
      <CButton :color="t==='monthly'?'primary':'secondary'" @click="t='monthly'">{{$t('cloud.title.stat_monthly')}}</CButton>
      <!-- <CButton :color="t==='custom'?'primary':'secondary'" @click="t='custom'">{{$t('cloud.title.stat_custom')}}</CButton> -->
    </CButtonGroup>
    <ParkingStatDaily v-if="t === 'daily'" />
    <ParkingStatWeekly v-else-if="t === 'weekly'" />
    <ParkingStatMonthly v-else-if="t === 'monthly'" />
    <ParkingStatCustom v-else-if="t === 'custom'" />
  </div>
</template>

<script>
import ParkingStatDaily from './Daily.vue'
import ParkingStatWeekly from './Weekly.vue'
import ParkingStatMonthly from './Monthly.vue'
import ParkingStatCustom from './Custom.vue'

export default {
  name: 'ParkingStatistics',

  components: {
    ParkingStatDaily,
    ParkingStatWeekly,
    ParkingStatMonthly,
    ParkingStatCustom
  },

  data () {
    return {
      t: 'daily'
    }
  },

}
</script>
