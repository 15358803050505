<template>
  <div>
    <ParkingStatFilter PERIOD="monthly" :BRANCH_OPTS="branch_list" @query="onQueryParking" />
    <CRow>
      <CCol md="12" lg="6">
        <ParkingOffPeakHour :stats="stats_parking_recent_occupancy" />
      </CCol>
      <CCol md="12" lg="6">
        <ParkingRecentOccupancyRate :stats="stats_parking_recent_occupancy" />
      </CCol>
      <CCol md="12" lg="6">
        <ParkingRecentEntryExit :stats="stats_parking_recent_entryexit" />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import ParkingStatFilter from './cards/StatFilter.vue'
import ParkingOffPeakHour from './cards/OffPeakHour.vue'
import ParkingRecentOccupancyRate from './cards/RecentOccupancyRate.vue'
import ParkingRecentEntryExit from './cards/RecentEntryExit.vue'
import { mapState } from 'vuex'

export default {
  name: 'ParkingStatisticsWeekly',

  components: {
    ParkingStatFilter,
    ParkingOffPeakHour,
    ParkingRecentOccupancyRate,
    ParkingRecentEntryExit
  },
  data() {
    return {
      filters: null,
      branch_list: [],
      stats_parking_recent_occupancy: null,
      stats_parking_recent_entryexit: null
    }
  },
  mounted() {
    this.initFetch();
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    regions() {
      let rtn = [];
      for (const branch of this.branch_list) {
        let rtn_region = rtn.find(el => el.name === branch.region_name);
        if (!rtn_region) {
          rtn.push({
            name: branch.region_name,
            id: branch.region,
            options: [
              {name: branch.name, id: branch.id}
            ]
          })
        } else {
          rtn_region.options.push({name: branch.name, id: branch.id});
        }
      }
      rtn = rtn.sort((a,b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
      });
      return rtn;
    }
  },
  methods: {
    initFetch() {
      this.getBranchList();
    },
    getBranchList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        // search: this.searchText,
        ipp: 100,
        page: 1
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/branches/${query_string}`)
        .then(result => {
          this.branch_list = result.data.list;
          // this.pageTotal = result.data.page.total;
          // this.pageCurrent = result.data.page.current;
          // this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        })
    },

    onQueryParking(stat_filter) {
      this.filters = stat_filter;
      // this.statCurrentOccupancy();
      this.statRecentOccupancy();
      this.statRecentEntryExit();
    },
    statRecentOccupancy() {
      let query_string = '';
      const params = {};
      if (this.filters) {
        params.branch = this.filters.branch;
        params.interval = 24;
        params.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (this.filters.date) {
          this.filters.date.setDate(1);
          this.filters.date.setHours(0);
          this.filters.date.setMinutes(0);
          this.filters.date.setSeconds(0);
          this.filters.date.setMilliseconds(0);
          params.sts = this.filters.date.getTime();

          let nowMonth = this.filters.date.getMonth();
          let nextMonth = new Date(params.sts);
          nextMonth.setMonth(nowMonth+1);
          params.ets = nextMonth.getTime();
        }
      }
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/parking/statistics/recent-occupancy/${query_string}`)
        .then(result => {
          this.stats_parking_recent_occupancy = result.data;
        })
        .catch(error => {
          console.error(error);
        })
    },
    statRecentEntryExit() {
      let query_string = '';
      const params = {};
      if (this.filters) {
        params.branch = this.filters.branch;
        params.interval = 24;
        params.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (this.filters.date) {
          this.filters.date.setDate(1);
          this.filters.date.setHours(0);
          this.filters.date.setMinutes(0);
          this.filters.date.setSeconds(0);
          this.filters.date.setMilliseconds(0);
          params.sts = this.filters.date.getTime();

          let nowMonth = this.filters.date.getMonth();
          let nextMonth = new Date(params.sts);
          nextMonth.setMonth(nowMonth+1);
          params.ets = nextMonth.getTime();
        }
      }
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/parking/statistics/recent-entry-exit/${query_string}`)
        .then(result => {
          this.stats_parking_recent_entryexit = result.data;
        })
        .catch(error => {
          console.error(error);
        })
    }
  }
}
</script>
